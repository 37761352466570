@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.show.linear_mask_transition.transition_maskRL {
  mask: linear-gradient(to left, #000 50%, transparent 51%) content-box right/0% 0% no-repeat;
  animation: show_0__200_-right-left 1000ms;
}
@keyframes show_0__200_-right-left {
  0% {
    mask-size: 0% 200%;
  }
  50% {
    mask-size: 200% 200%;
  }
  100% {
    mask-size: 200% 200%;
  }
}
.show.linear_mask_transition.transition_maskLR {
  mask: linear-gradient(to right, #000 50%, transparent 51%) content-box left/0% 0% no-repeat;
  animation: show_0__200_-left-right 1000ms;
}
@keyframes show_0__200_-left-right {
  0% {
    mask-size: 0% 200%;
  }
  50% {
    mask-size: 200% 200%;
  }
  100% {
    mask-size: 200% 200%;
  }
}
.show.linear_mask_transition.transition_maskBT {
  mask: linear-gradient(to top, #000 50%, transparent 51%) content-box bottom/0% 0% no-repeat;
  animation: show_200__0_-bottom-top 1000ms;
}
@keyframes show_200__0_-bottom-top {
  0% {
    mask-size: 200% 0%;
  }
  50% {
    mask-size: 200% 200%;
  }
  100% {
    mask-size: 200% 200%;
  }
}
.show.linear_mask_transition.transition_maskTB {
  mask: linear-gradient(to bottom, #000 50%, transparent 51%) content-box top/0% 0% no-repeat;
  animation: show_200__0_-top-bottom 1000ms;
}
@keyframes show_200__0_-top-bottom {
  0% {
    mask-size: 200% 0%;
  }
  50% {
    mask-size: 200% 200%;
  }
  100% {
    mask-size: 200% 200%;
  }
}
.hide.transition_instant {
  display: none !important;
}
.hide.linear_mask_transition.transition_maskRL {
  mask: linear-gradient(to right, #000 50%, transparent 51%) content-box left/0% 0% no-repeat;
  animation: hide_0__200_-right-left 1000ms;
}
@keyframes hide_0__200_-right-left {
  0% {
    mask-size: 200% 200%;
  }
  50% {
    mask-size: 0% 200%;
  }
  100% {
    mask-size: 200% 200%;
  }
}
.hide.linear_mask_transition.transition_maskLR {
  mask: linear-gradient(to left, #000 50%, transparent 51%) content-box right/0% 0% no-repeat;
  animation: hide_0__200_-left-right 1000ms;
}
@keyframes hide_0__200_-left-right {
  0% {
    mask-size: 200% 200%;
  }
  50% {
    mask-size: 0% 200%;
  }
  100% {
    mask-size: 200% 200%;
  }
}
.hide.linear_mask_transition.transition_maskBT {
  mask: linear-gradient(to bottom, #000 50%, transparent 51%) content-box top/0% 0% no-repeat;
  animation: hide_200__0_-bottom-top 1000ms;
}
@keyframes hide_200__0_-bottom-top {
  0% {
    mask-size: 200% 200%;
  }
  50% {
    mask-size: 200% 0%;
  }
  100% {
    mask-size: 200% 200%;
  }
}
.hide.linear_mask_transition.transition_maskTB {
  mask: linear-gradient(to top, #000 50%, transparent 51%) content-box bottom/0% 0% no-repeat;
  animation: hide_200__0_-top-bottom 1000ms;
}
@keyframes hide_200__0_-top-bottom {
  0% {
    mask-size: 200% 200%;
  }
  50% {
    mask-size: 200% 0%;
  }
  100% {
    mask-size: 200% 200%;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --r-ntv-1-f-dark: #FF0095;
  --r-ntv-1-t-dark: #FFEA00;
  --r-ntv-1-f-light: #007FDA;
  --r-ntv-1-t-light: #00DAC8;
  --r-ntv-2-f-dark: #0095FF;
  --r-ntv-2-t-dark: #00FFE9;
  --r-ntv-2-f-light: #DA007F;
  --r-ntv-2-t-light: #C800DA;
  --r-gold-1: #bea200;
  --r-gold-2: #ffd900;
  --r-silver-1: #C0C0C0;
  --r-silver-2: #E0E0E0;
  --r-bronze-1: #E58B4B;
  --r-bronze-2: #EDAF82;
}

.idle-zoom {
  animation: idle-zoom-1-1---02-false 10s ease-in-out infinite;
}
@keyframes idle-zoom-1-1---02-false {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.idle-zoom-lg {
  animation: idle-zoom-1-1---05-false 10s ease-in-out infinite;
}
@keyframes idle-zoom-1-1---05-false {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.idle-zoom-xl {
  animation: idle-zoom-1-1---08-false 10s ease-in-out infinite;
}
@keyframes idle-zoom-1-1---08-false {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}

.r-norm-list {
  line-height: 2rem;
}
.r-norm-list ul, .r-norm-list ol, .r-norm-list menu {
  list-style: initial !important;
}
.r-norm-list a {
  color: oklch(var(--p));
}
.r-norm-list ul > li > ul {
  margin-left: 1rem;
  list-style: circle !important;
}

.mcbg {
  background: linear-gradient(to top right, var(--r-ntv-2-t-dark) 3%, var(--r-ntv-1-t-dark) 38%, var(--r-ntv-2-f-dark) 68%, var(--r-ntv-2-t-dark) 98%), rgba(255, 255, 255, 0.6666666667);
  animation: mcbg_gradient 100s ease infinite;
  background-blend-mode: multiply;
  background-size: 400% 400%;
  background-attachment: fixed;
}

@keyframes mcbg_gradient {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}
.wave {
  background: rgba(255, 255, 255, 0.33);
  border-radius: 1000% 1000% 0 0;
  position: fixed;
  width: 200%;
  height: 4rem;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.wave :nth-of-type(2) {
  bottom: -1.25rem;
  animation: wave 18s linear reverse infinite;
  opacity: 0.8;
}
.wave :nth-of-type(3) {
  bottom: -2.5rem;
  animation: wave 20s -1s reverse infinite;
  opacity: 0.9;
}

@keyframes wave {
  2% {
    transform: translateX(1);
  }
  25% {
    transform: translateX(-25%);
  }
  50% {
    transform: translateX(-50%);
  }
  75% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(1);
  }
}
.font-inter {
  font-family: "Inter", sans-serif;
}

