@use "sass:meta";

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

$ff: "Montserrat", serif;

$fs-xxs: 10px;
$fs-xs: 12px;
$fs-s: 14px;
$fs-n: 16px;
$fs-l: 18px;
$fs-xl: 20px;
$fs-xxl: 22px;

$sep-d: 10px;
$large-pad: min(5vw, 100px);
$huge-pad: min(10vw, 200px);

$br-n: 10px;

$w-bd: 1px;
$bd: $w-bd solid var(--c-bd);

$ds-sz-def: 0 0 5px;
$ds-msz-def: 0 0 2px;

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  
  @return $string;
}

@mixin apply-to-all($mixin, $list) {
  @each $element in $list {
    @include meta.apply($mixin, $element);
  }
}

@mixin idle-zoom($min-scale, $max-scale, $duration-per-cycle, $background-only) {
  $diff-scale: calc($max-scale - $min-scale);
  $animation-name: str-replace(idle-zoom-#{$min-scale}-#{$max-scale}-#{$background-only}, ".", "---");

  @if $background-only {
    @keyframes #{$animation-name} {
      0% {
        background-size: $min-scale;
      }
      50% {
        background-size: $max-scale;
      }
      100% {
        background-size: $min-scale;
      }
    }
  } @else {
    @keyframes #{$animation-name} {
      0% {
        transform: scale($min-scale);
      }
      50% {
        transform: scale($max-scale);
      }
      100% {
        transform: scale($min-scale);
      }
    }
  }
  animation: $animation-name $duration-per-cycle ease-in-out infinite;
}

