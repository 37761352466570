@use "sass:string";
@use "../vars";

$transition_length: 500ms;

%linear_mask {
  mask-composite: intersect;
}

@mixin show_anim($from, $to, $start_size) {
  $anim-name: show_#{vars.str-replace(vars.str-replace(#{$start_size}, " ", "_"), "%", "_")}-#{$from}-#{$to};

  @keyframes #{$anim-name} {
    0% {
      mask-size: $start_size;
    }
    50% {
      mask-size: 200% 200%;
    }
    100% {
      mask-size: 200% 200%;
    }
  }

  mask: linear-gradient(to $to, #000 50%, transparent 51%) content-box $from / 0% 0% no-repeat;

  animation: $anim-name calc(2 * $transition_length);
}

@mixin hide_anim($from, $to, $target_size) {
  $anim-name: hide_#{vars.str-replace(vars.str-replace(#{$target_size}, " ", "_"), "%", "_")}-#{$from}-#{$to};

  @keyframes #{$anim-name} {
    0% {
      mask-size: 200% 200%;
    }
    50% {
      mask-size: $target_size;
    }
    100% {
      mask-size: 200% 200%;
    }
  }

  mask: linear-gradient(to $from, #000 50%, transparent 51%) content-box $to / 0% 0% no-repeat;

  animation: $anim-name calc(2 * $transition_length);
}

.show {
  &.linear_mask_transition {
    &.transition_maskRL {
      @include show_anim(right, left, 0% 200%);
    }
    &.transition_maskLR {
      @include show_anim(left, right, 0% 200%);
    }
    &.transition_maskBT {
      @include show_anim(bottom, top, 200% 0%);
    }
    &.transition_maskTB {
      @include show_anim(top, bottom, 200% 0%);
    }
  }
  &.transition_maskCO {

  }
  &.transition_maskOC {

  }
}

.hide {
  &.transition_instant {
    display: none!important;
  }
  &.linear_mask_transition {
    &.transition_maskRL {
      @include hide_anim(right, left, 0% 200%);
    }
    &.transition_maskLR {
      @include hide_anim(left, right, 0% 200%);
    }
    &.transition_maskBT {
      @include hide_anim(bottom, top, 200% 0%);
    }
    &.transition_maskTB {
      @include hide_anim(top, bottom, 200% 0%);
    }
  }
  &.transition_maskCO {

  }
  &.transition_maskOC {

  }
}