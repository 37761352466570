@use "misc/transitions" as *;
@use "vars" as *;

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --r-ntv-1-f-dark: #FF0095;
  --r-ntv-1-t-dark: #FFEA00;
  --r-ntv-1-f-light: #007FDA;
  --r-ntv-1-t-light: #00DAC8;

  --r-ntv-2-f-dark: #0095FF;
  --r-ntv-2-t-dark: #00FFE9;
  --r-ntv-2-f-light: #DA007F;
  --r-ntv-2-t-light: #C800DA;

  --r-gold-1: #bea200;
  --r-gold-2: #ffd900;

  --r-silver-1: #C0C0C0;
  --r-silver-2: #E0E0E0;

  --r-bronze-1: #E58B4B;
  --r-bronze-2: #EDAF82;
}

.idle-zoom {
  @include idle-zoom(1, 1.02, 10s, false);
}

.idle-zoom-lg {
  @include idle-zoom(1, 1.05, 10s, false);
}

.idle-zoom-xl {
  @include idle-zoom(1, 1.08, 10s, false);
}

.r-norm-list {
  line-height: 2rem;
  ul,ol,menu {
    list-style: initial!important;
  }
  a {
    color: oklch(var(--p))
  }
  ul > li > ul {
    margin-left: 1rem;
    list-style: circle!important;
  }
}

// thanks to: https://codepen.io/baarbaracrr/pen/KKovmGb for letting me use their cool design.
.mcbg {
  background: linear-gradient(to top right, var(--r-ntv-2-t-dark) 3%, var(--r-ntv-1-t-dark) 38%, var(--r-ntv-2-f-dark) 68%, var(--r-ntv-2-t-dark) 98%), #fffa;
  animation: mcbg_gradient 100s ease infinite;
  background-blend-mode: multiply;
  background-size: 400% 400%;
  background-attachment: fixed;
}
@keyframes mcbg_gradient {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.wave {
  background: rgb(255 255 255 / 33%);
  border-radius: 1000% 1000% 0 0;
  position: fixed;
  width: 200%;
  height: 4rem;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  bottom: 0;
  left: 0;
  z-index: 2;
  :nth-of-type(2) {
    bottom: -1.25rem;
    animation: wave 18s linear reverse infinite;
    opacity: 0.8;
  }
  :nth-of-type(3) {
    bottom: -2.5rem;
    animation: wave 20s -1s reverse infinite;
    opacity: 0.9;
  }
}
@keyframes wave {
  2% {
    transform: translateX(1);
  }

  25% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateX(-50%);
  }

  75% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(1);
  }
}

.font-inter {
  font-family: "Inter", sans-serif;
}
